<template>
<b-col>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col class="my-5"><h3>By number of sources (feeds) in each bucket</h3></b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="mb-4" v-for="chart in barCharts1" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="mb-4" v-for="chart in barCharts2" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-col>
</template>

<script>
import _ from 'lodash'
// import * as echarts from 'echarts'
import dataGetters from '@/libs/dataGetters'
// import theme from '@/libs/colorTheme'
import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
// echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'SourcesDataStrats',
  components: {
    BarStackedSimple
  },
  computed: {
    barCharts1: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'property',
          property: 'countryName',
          nameProperty: 'countryName',
          nameSeries: 'Country',
          header: 'Country',
          headerRecord: 'Countries'
        },
        {
          dataGetter: 'property',
          property: 'publisher',
          nameProperty: 'publisher',
          nameSeries: 'Publisher',
          header: 'Publishers',
          headerRecord: 'publishers'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barCharts2: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'property',
          property: 'broken',
          nameProperty: 'broken',
          nameSeries: 'Broken',
          header: 'Broken',
          headerRecord: 'states'
        },
        {
          dataGetter: 'property',
          property: 'alert',
          nameProperty: 'alert',
          nameSeries: 'Alert active',
          header: 'Alert active',
          headerRecord: 'states'
        },
        {
          dataGetter: 'property',
          property: 'client',
          nameProperty: 'client',
          nameSeries: 'Client',
          header: 'Client',
          headerRecord: 'states'
        },
        {
          dataGetter: 'property',
          property: 'parser',
          nameProperty: 'parser',
          nameSeries: 'Parser',
          header: 'Parser',
          headerRecord: 'parsers'
        },
        {
          dataGetter: 'property',
          property: 'maxdate',
          nameProperty: 'maxdate',
          nameSeries: 'Most recent date',
          header: 'Most recent date',
          headerRecord: 'dates'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    rows: function () {
      return this.$store.state.rowsShow
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "MAR Cases Stratification"
    this.$stat.log({ page: 'cases datastrats', action: 'open cases datastrats' })
  }
}
</script>

<style>
</style>
